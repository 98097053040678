import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./SchedulePicker.module.css";

import {
  openSchedule,
  closeSchedule,
} from "../../../../../redux/modules/content/schedule";

/**
 * @author  Conan Lai
 * @description on mobile, this component is just a button that closes (today's) schedule
 * but on desktop, it allows user to pick days
 */

/**
   this array below maps our UI representation of days (i.e MON, TUES, etc)
   to the Airtime API representation of days (i.e monday, nextmonday, etc)
   so we can correctly map our buttons to the correct part of the Airtime payload
*/

const days = [
  {
    day: "sunday",
    label: "SUN",
  },
  {
    day: "monday",
    label: "MON",
  },
  {
    day: "tuesday",
    label: "TUE",
  },
  {
    day: "wednesday",
    label: "WED",
  },
  {
    day: "thursday",
    label: "THU",
  },
  {
    day: "friday",
    label: "FRI",
  },
  {
    day: "saturday",
    label: "SAT",
  },
  {
    day: "nextsunday",
    label: "SUN",
  },
  {
    day: "nextmonday",
    label: "MON",
  },
  {
    day: "nexttuesday",
    label: "TUE",
  },
  {
    day: "nextwednesday",
    label: "WED",
  },
  {
    day: "nextthursday",
    label: "THU",
  },
  {
    day: "nextfriday",
    label: "FRI",
  },
  {
    day: "nextsaturday",
    label: "SAT",
  },
];

class SchedulePicker extends React.PureComponent {
  render() {
    const today = new Date();
    let day = today.getDay();
    const slice = days.slice(day, day + 3);
    const display = this.props.isMobile ? (
      <div className={styles.schedulepicker}>
        <div
          key={days[day].day}
          onClick={() =>
            this.props.selectedDay === days[day].day
              ? this.props.closeSchedule()
              : this.props.openSchedule(days[day].day)
          }
          className={styles.schedulepickerday}
        >
          {this.props.selectedDay === days[day].day ? "CLOSE" : "SCHEDULE"}
        </div>
      </div>
    ) : (
      <div className={styles.schedulepicker}>
        {slice.map((i) => {
          return (
            <div
              key={i.day}
              onClick={() =>
                this.props.selectedDay === i.day
                  ? this.props.closeSchedule()
                  : this.props.openSchedule(i.day)
              }
              className={
                styles.schedulepickerday + " ignore-react-onclickoutside"
              }
            >
              {this.props.selectedDay === i.day ? "CLOSE" : i.label}
            </div>
          );
        })}
      </div>
    );
    return display;
  }
}

SchedulePicker.propTypes = {
  isMobile: PropTypes.bool,
  openSchedule: PropTypes.func,
  closeSchedule: PropTypes.func,
  selectedDay: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    isMobile: state._ui.isMobile,
    selectedDay: state._schedule.selectedDay,
  };
};

const ConnectedSchedulePicker = connect(mapStateToProps, {
  openSchedule,
  closeSchedule,
})(SchedulePicker);

export default ConnectedSchedulePicker;
