module.exports = {
  port: process.env.PORT || 3000,
  cognito_user_pool_id:
    process.env.COGNITO_CLIENT_ID_USER_POOL_ID || "us-east-2_aturij12W",
  cognito_client_id:
    process.env.COGNITO_CLIENT_ID || "2j9acrg9lgv8b08u3pljqg9nd5",
  node_env: process.env.NODE_ENV || "development",
  stream_url:
    process.env.STREAM_URL || "https://n10as.radiocult.fm/stream",
  api_path: process.env.REACT_APP_API_PATH || "http://localhost:4000",
};
