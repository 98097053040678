import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import styles from "../../SideBar.module.css";

import icon from "../../imgs/icons_schedule.png";

import {
  openSchedule,
  closeSchedule,
} from "../../../../redux/modules/content/schedule";
import { openArchive, closeArchive } from "../../../../redux/modules/archives";

/**
 * @author  Conan Lai
 * @description takes in height/width prop from Icons component, which calculates size based
 * on number of icons
 */

const days = [
  {
    day: "sunday",
    label: "SUN",
  },
  {
    day: "monday",
    label: "MON",
  },
  {
    day: "tuesday",
    label: "TUE",
  },
  {
    day: "wednesday",
    label: "WED",
  },
  {
    day: "thursday",
    label: "THU",
  },
  {
    day: "friday",
    label: "FRI",
  },
  {
    day: "saturday",
    label: "SAT",
  },
  {
    day: "nextsunday",
    label: "SUN",
  },
  {
    day: "nextmonday",
    label: "MON",
  },
  {
    day: "nexttuesday",
    label: "TUE",
  },
  {
    day: "nextwednesday",
    label: "WED",
  },
  {
    day: "nextthursday",
    label: "THU",
  },
  {
    day: "nextfriday",
    label: "FRI",
  },
  {
    day: "nextsaturday",
    label: "SAT",
  },
];

class ScheduleIcon extends React.Component {
  render() {
    const today = moment.tz("America/New_York");
    let day = today.day();
    return (
      <div
        className={styles.element + " ignore-react-onclickoutside"}
        style={{
          height: this.props.height ? `${this.props.height}%` : null,
          width: this.props.width ? `${this.props.width}%` : null,
        }}
        onClick={(e) => {
          if (!this.props.isScheduleOpen) {
            this.props.openSchedule(days[day].day);
          } else {
            this.props.closeSchedule();
          }
          if (this.props.isArchiveOpen) {
            this.props.closeArchive();
          }
        }}
      >
        <div className={styles.imgwrapper}>
          <img src={icon} />
        </div>
      </div>
    );
  }
}

ScheduleIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  openSchedule: PropTypes.func,
  closeSchedule: PropTypes.func,
  isScheduleOpen: PropTypes.bool,
  openArchive: PropTypes.func,
  closeArchive: PropTypes.func,
  isArchiveOpen: PropTypes.bool,
};

const mapPropsToState = (state) => {
  return {
    z: state._shop.z,
    isScheduleOpen: state._schedule.open,
    isArchiveOpen: state._archives.open,
  };
};

const ConnectedScheduleIcon = connect(mapPropsToState, {
  openSchedule,
  closeSchedule,
  openArchive,
  closeArchive,
})(ScheduleIcon);

export default ConnectedScheduleIcon;
