import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ScheduleHolder from "./Schedule/ScheduleHolder";
import ArchivesHolder from "./Archives/ArchivesHolder";
import NextPlaying from "./NextPlaying/NextPlaying";
import styles from "./ArchivesAndSchedule.module.css";

import {
  openSchedule,
  closeSchedule,
} from "../../../redux/modules/content/schedule";
import { openArchive, closeArchive } from "../../../redux/modules/archives";

/**
 * @author  Conan Lai
 * @description container holding Archives, Schedule, and the buttons to open them
 */

/**
   this array below maps our UI representation of days (i.e MON, TUES, etc)
   to the Airtime API representation of days (i.e monday, nextmonday, etc)
   so we can correctly map our buttons to the correct part of the Airtime payload
*/

const days = [
  {
    day: "sunday",
    label: "SUN",
  },
  {
    day: "monday",
    label: "MON",
  },
  {
    day: "tuesday",
    label: "TUE",
  },
  {
    day: "wednesday",
    label: "WED",
  },
  {
    day: "thursday",
    label: "THU",
  },
  {
    day: "friday",
    label: "FRI",
  },
  {
    day: "saturday",
    label: "SAT",
  },
  {
    day: "nextsunday",
    label: "SUN",
  },
  {
    day: "nextmonday",
    label: "MON",
  },
  {
    day: "nexttuesday",
    label: "TUE",
  },
  {
    day: "nextwednesday",
    label: "WED",
  },
  {
    day: "nextthursday",
    label: "THU",
  },
  {
    day: "nextfriday",
    label: "FRI",
  },
  {
    day: "nextsaturday",
    label: "SAT",
  },
];

class ArchivesAndSchedule extends React.Component {
  render() {
    const today = moment.tz("America/New_York");
    let day = today.day();
    return (
      <div className={styles.archivesandschedule}>
        <div className={styles.next}>
          <div className={styles.nextLabel}>→</div>
          <div className={styles.nextPlaying}>
            <NextPlaying />
          </div>
          {/*<NextPlaying />*/}
        </div>
        <div className={styles.buttons}>
          <div
            className={styles.scheduleButton + " ignore-react-onclickoutside"}
            onClick={(e) => {
              if (this.props.isArchiveOpen === true) {
                this.props.closeArchive();
              }
              if (this.props.isScheduleOpen === false) {
                this.props.openSchedule(days[day].day);
              } else {
                this.props.closeSchedule();
              }
            }}
          >
            SCHEDULE
          </div>
          <div
            className={styles.archiveButton + " ignore-react-onclickoutside"}
            onClick={(e) => {
              if (this.props.isScheduleOpen === true) {
                this.props.closeSchedule();
              }
              if (this.props.isArchiveOpen === false) {
                this.props.openArchive();
              } else {
                this.props.closeArchive();
              }
            }}
          >
            ARCHIVE
          </div>
        </div>
        <ScheduleHolder />
        <ArchivesHolder url="https://api.mixcloud.com/n10as/cloudcasts/" />
      </div>
    );
  }
}

ArchivesAndSchedule.propTypes = {
  openArchive: PropTypes.func,
  closeArchive: PropTypes.func,
  openSchedule: PropTypes.func,
  closeSchedule: PropTypes.func,
  isArchiveOpen: PropTypes.bool,
  isScheduleOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isMobile: state._ui.isMobile,
    isScheduleOpen: state._schedule.open,
    isArchiveOpen: state._archives.open,
  };
};

const ConnectedArchivesAndSchedule = connect(mapStateToProps, {
  openSchedule,
  closeSchedule,
  openArchive,
  closeArchive,
})(ArchivesAndSchedule);

export default ConnectedArchivesAndSchedule;
